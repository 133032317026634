import { dict } from '@/stores/dict';
import CommonService from '@/service/CommonService';
import { ref, toRefs } from 'vue';
import _ from 'lodash';


const dictLoadingKey = [];

/**
 * 获取字典数据
 */
// eslint-disable-next-line
export function useDict(...args) {
	const res = ref({});
	return (() => {
		args.forEach((dictType) => {
			// @ts-ignore
			res.value[dictType] = [];
			const dicts = dict().getDict(dictType);
			if (dicts) {
				// @ts-ignore
				res.value[dictType] = dicts;
			} else {
                if(_.indexOf(dictLoadingKey,dictType) === -1 && dictType){
                    dictLoadingKey.push(dictType);
                    CommonService.getDicts(dictType).then((resp) => {
                        // @ts-ignore
                        res.value[dictType] = resp.data.map((p) => ({ label: p.label, value: p.value, elTagType: p.listClass, elTagClass: p.cssClass }));
                        // @ts-ignore
                        dict().setDict(dictType, res.value[dictType]);
                    })
                    .finally(()=>{
                        _.pull(dictLoadingKey,dictType)
                    })
                }
			}
		});
		return toRefs(res.value);
	})();
}

export const findDictItem = (arr, val) => {
	return _.find(arr, { value: val });
};

export const findLabel = (arr, val) => {
	const obj = findDictItem(arr, val);
	return obj ? obj.label : val;
};
